import API from '../../axiosInstance'


const devicesAPI = {
    getDevices: async (accountId) => {
        try {
            const counts = {}
            const result = await API().get(`/accounts/${accountId}/devices`) // returns array of {devices}
            // This is to make sure that macId is macAddress for each object. If macId is changed to macAddress on the backend, or the result made an object, this part can go away
            const devices = result.data.map(devicesResult => {
                const device = devicesResult
                if (device.macId) {
                    device.macAddress = device.macId
                }

                if (!counts[device.dsn]) {
                    counts[device.dsn] = 1
                }
                else {
                    counts[device.dsn] += 1
                }

                return device
            })

            // Remove orphans.
            return devices.filter((device) => {
                return counts[device.dsn] === 1 || device.serviceUserIds.length > 0
            })
        } catch (error) {
            console.error(error)
            return null
        }
    },
    getDeviceByDsnSearch: async (queryParam) => {
        try {
            const result = await API().get(`/accounts/-/devices`, {  // returns array of {devices}
                params: {
                    query: `"dsn=${queryParam}"`
                }
            })
            if (!result.data || !result.data.length) {
                return null
            }
            return result.data
        } catch (error) {
            console.error(error)
            return null
        }
    },
    getDeviceByDsn: async (queryParam) => { // NOTE: this *should* be getDeviceByDeviceId, but backend can't do that yet
        try {
            const result = await API().get(`/accounts/-/devices`, {  // returns array of {devices}
                params: {
                    query: `"dsn=${queryParam}"`
                }
            })
            if (result.data instanceof Array) {
                return result.data[0]
            }
            return null
        } catch (error) {
            console.error(error)
            return null
        }
    },
    unpairDevice: async (accountId, dsn) => {
        try {
            const result = await API().post(`/accounts/${accountId}/devices:unpair`,
                {
                    dsn
                }
            ) // returns 204 if successful
            if (result.status >= 200 && result.status < 300) {
                return true
            }
        } catch (error) {
            console.error(error)
        }
        return false
    },
    unregisterDevice: async (dsn, accountId) => {
        try {
            const result = await API().delete(`/accounts/${accountId}/devices:unregister`,
                {
                    data: {
                        dsn,
                        uid: accountId,
                    }
                }
            ) // returns 204 if successful
            if (result.status >= 200 && result.status < 300) {
                return true
            }
        } catch (error) {
            console.error(error)
        }
        return false
    },
    migrateSock: async (accountId, deviceId, migrationType) => {
        try {
            const result = await API().post(`/accounts/${accountId}/devices/${deviceId}:migrate`, {
              migrationType,
            })
            // returns 204 if successful
            if (result.status >= 200 && result.status < 300) {
                return true
            }
        } catch (error) {
            console.error(error)
        }
        return false
    },
    generateSleepData: async (accountId, deviceId, startTime, duration) => {
      try {
            const result = await API().post(`/accounts/${accountId}/devices/${deviceId}:generate-sleep-data`, {
              startTime,
              duration,
            })
            // returns 204 if successful
            if (result.status >= 200 && result.status < 300) {
                return true
            }
      } catch (error) {
            console.error(error)
      }
      return false
    },
    generateSevenDaysData: async (accountId, deviceId, dataTemplate) => {
        try {
              const result = await API().post(`/accounts/${accountId}/devices/${deviceId}:generate-seven-days-data`,
                {...dataTemplate},
              )
              // returns 204 if successful
              if (result.status >= 200 && result.status < 300) {
                  return true
              }
        } catch (error) {
              console.error(error)
              console.error(`DATA: ${dataTemplate}`)
        }
        return false
      },
    injectInsightsData: async (accountId, serviceUserId, dataTemplate) => {
        try {
              const result = await API().post(`/accounts/${accountId}/serviceUsers/${serviceUserId}:inject-insights-response`,
                {...dataTemplate},
              )
              // returns 204 if successful
              if (result.status >= 200 && result.status < 300) {
                  return true
              }
        } catch (error) {
              console.error(error)
              console.error(`DATA: ${dataTemplate}`)
        }
        return false
      },
    deleteSevenDaysData: async (accountId, deviceId,) => {
        try {
              const result = await API().delete(`/accounts/${accountId}/devices/${deviceId}:delete-seven-days-data`)
              // returns 204 if successful
              if (result.status >= 200 && result.status < 300) {
                  return true
              }
        } catch (error) {
              console.error(error)
        }
        return false
      },
    getNotificationsHistory: async (accountId, deviceId) => {
      try {
          const result = await API().get(`/accounts/${accountId}/devices/${deviceId}/notifications`)

          if (!result.data || !result.data.length) {
              return null
          }

          return result.data
      } catch (error) {
          console.error(error)
          return null
      }
    },
    // TODO: These endpoints are to be used in later iterations of the backend

    // getDevices: async (suid) => {
    //     try {
    //         const result = await API().get(`/accounts/${suid}/devices`) // returns array of {devices}
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, devicesData)
    //     }
    //     return {}
    // },
    // getServiceUserDevicesByAccountKey: async (accountKey) => {
    //     try {
    //         const result = await API().get(`/accounts/${accountKey}/serviceUsers/-/devices`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, devicesData)
    //     }
    //     return {}
    // },
    // getDeviceByDsnWithAccountKeyAndSUID: async (accountKey, suid, dsn) => {
    //     try {
    //         const result = await API().get(`/accounts/${accountKey}/serviceUsers/${suid}/devices/${dsn}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // createDeviceAndAssociateToAccount: async (accountKey, suid) => {
    //     try {
    //         const result = await API().post(`/accounts/${accountKey}/serviceUsers/${suid}/devices/`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // deleteDeviceByDSN: async (accountKey, serviceUser, dsn) => {
    //     try {
    //         const result = await API().delete(`/accounts/${accountKey}/serviceUsers/${serviceUser}/devices/${dsn}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // putDevice: async (accountKey, serviceUser, dsn) => {
    //     try {
    //         const result = await API().put(`/accounts/${accountKey}/serviceUsers/${serviceUser}/devices/${dsn}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // patchDevice: async (accountKey, serviceUser, dsn) => {
    //     try {
    //         const result = await API().patch(`/accounts/${accountKey}/serviceUsers/${serviceUser}/devices/${dsn}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // colorDebug: async (accountKey, suid, dsn, lightColor) => {
    //     try {
    //         const result = await API().post(`/accounts/${accountKey}/serviceUsers/${suid}/devices/${dsn}:${lightColor}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // pairDevice: async (accountKey, suid, dsn, pair) => {
    //     try {
    //         const result = await API().post(`/accounts/${accountKey}/serviceUsers/${suid}/devices/${dsn}:${pair}`) // returns 404 not found
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },

    // getAllBandSessionsForAccount: async (accountKey, suid, dsn) => {
    //     try {
    //         const result = await API().get(`/accounts/${accountKey}/serviceUsers/${suid}/devices/${dsn}/sessions`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
    // getBandSessionInfo: async (accountKey, suid, dsn, sessionId) => {
    //     try {
    //         const result = await API().get(`/accounts/${accountKey}/serviceUsers/${suid}/devices/${dsn}/sessions/${sessionId}`) // returns 501 not implemented
    //         return result
    //     } catch (error) {
    //         isDevelopment(error, deviceData)
    //     }
    //     return {}
    // },
}

export default devicesAPI
