import React, { useContext, useState } from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { injectInsightsData } from '../../../../api/lib/apiV1'
import '../../../common/Layout/Buttons.css'
import { SnackbarContext } from '../../../common/Snackbar'


const InjectInsightsData = ({ currentDevice }) => {
  const snackbar = useContext(SnackbarContext)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const { accountId, serviceUserId, userProfile } = currentDevice
  const childName = userProfile && userProfile.firstName ? userProfile.firstName : 'Baby'

  const getLocalTimezoneOffsetISO = () => {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();
    const sign = offsetInMinutes <= 0 ? '+' : '-';
    const absOffsetInMinutes = Math.abs(offsetInMinutes);
    const hours = String(Math.floor(absOffsetInMinutes / 60)).padStart(2, '0');
    const minutes = String(absOffsetInMinutes % 60).padStart(2, '0');
    return `${sign}${hours}:${minutes}`;
  }

  const timezone = getLocalTimezoneOffsetISO();

  const getLast12HoursTimestamps = () => {
    const timestamps = [];
    const now = new Date();

    for (let i = 24; i >= 12; i-=1) {
      const date = new Date(now.getTime() - i * 60 * 60 * 1000); // Subtract hours
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      timestamps.push(`${hours}:${minutes}`);
    }

    return timestamps;
  };

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const cam12hours = getLast12HoursTimestamps();

  const dayAbbreviations = [
    "S",
    "M",
    "T",
    "W",
    "Th",
    "Fr",
    "S",
  ]

  const getLast7DaysISO = () => {
    const dates = [];
    const daysOfWeek = [];
    for (let i = 0; i < 7; i+=1) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      daysOfWeek.push(dayAbbreviations[date.getDay()]);
      dates.push(date.toLocaleDateString('en-CA'));
    }
    return {dates, daysOfWeek};
  };

  const {dates, daysOfWeek} = getLast7DaysISO();

  const defaultTemplate = `
  {
    "healthSummary": {
        "name": "accounts/${accountId}/profiles/${serviceUserId}/health-summary",
        "todaysSummary": "Use your Dream Sock to unlock insights.",
        "sockWearingScore": 14,
        "ageInWeeks": 14,
        "ageInMonths": 3,
        "pulseRateInsights": {
            "funInsight": "${childName} is 'Perfect' and Ed Sheeran must agree because his song is following the tempo of ${childName}'s heartbeat.",
            "trendInsight": "No Pulse Rate data for today.",
            "comparativeInsight": "${childName}'s average Pulse Rate is outside of the typical range for Owlet Babies.",
            "weeklyAvg": 96
        },
        "oxygenInsights": {
            "funInsight": "Periodic breathing: It might seem strange, but babies often pause for a few seconds between breaths, especially when they're sleeping. This is called periodic breathing, and it's completely normal!",
            "trendInsight": "No Oxygen Saturation data for today.",
            "comparativeInsight": "${childName}'s average Oxygen Saturation is slightly outside the majority's average for Owlet Babies.",
            "weeklyAvg": 97
        },
        "comfortTemperatureInsights": {
            "trendInsight": "Keep using your Dream Sock to unlock trends.",
            "weeklyReport": "${childName}'s Comfort Temperature was the perfect level of cozy for 1 out of 7 days this week!"
        },
        "movementInsights": {
            "trendInsight": "Keep using your Dream Sock to unlock trends.",
            "weeklyReport": "Newborns are extra wiggly sleepers! They have something called the Moro Reflex. This is a fancy way of saying their arms and legs might twitch or jerk on their own since they're still learning to control their bodies. It's all part of their amazing development! But it can also wake them up from sleep, using a swaddle (until 8 weeks of age or signs of rolling), can help minimize these reflexes."
        },
        "sevenDay": [
            {
                "summaryDate": "${dates[6]}",
                "dayLabelAbbreviated": "${daysOfWeek[6]}",
                "usedSock": false,
                "pulseRate": {
                    "insight": "No Pulse Rate range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 0
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "No Oxygen Saturation range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 0
                        }
                    ]
                },
                "temperature": null,
                "movement": null
            },
            {
                "summaryDate": "${dates[5]}",
                "dayLabelAbbreviated": "${daysOfWeek[5]}",
                "usedSock": false,
                "pulseRate": {
                    "insight": "No Pulse Rate range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 0
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "No Oxygen Saturation range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 0
                        }
                    ]
                },
                "temperature": null,
                "movement": null
            },
            {
                "summaryDate": "${dates[4]}",
                "dayLabelAbbreviated": "${daysOfWeek[4]}",
                "usedSock": true,
                "pulseRate": {
                    "insight": "${childName} spent the most time in the 80-99 bpm range.",
                    "min": 90,
                    "max": 100,
                    "avg": 96,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 5.3
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "${dates} spent the most time in the 95-100% range.",
                    "min": 95,
                    "max": 100,
                    "avg": 97,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 8.0
                        }
                    ]
                },
                "temperature": {
                    "deviation": 0,
                    "trending": "AVERAGE",
                    "gauge": 50
                },
                "movement": {
                    "deviation": 0,
                    "trending": null,
                    "gauge": null
                }
            },
            {
                "summaryDate": "${dates[3]}",
                "dayLabelAbbreviated": "${daysOfWeek[3]}",
                "usedSock": false,
                "pulseRate": {
                    "insight": "No Pulse Rate range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 0
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "No Oxygen Saturation range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 0
                        }
                    ]
                },
                "temperature": null,
                "movement": null
            },
            {
                "summaryDate": "${dates[2]}",
                "dayLabelAbbreviated": "${daysOfWeek[2]}",
                "usedSock": false,
                "pulseRate": {
                    "insight": "No Pulse Rate range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 0
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "No Oxygen Saturation range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 0
                        }
                    ]
                },
                "temperature": null,
                "movement": null
            },
            {
                "summaryDate": "${dates[1]}",
                "dayLabelAbbreviated": "${daysOfWeek[1]}",
                "usedSock": false,
                "pulseRate": {
                    "insight": "No Pulse Rate range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 0
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "No Oxygen Saturation range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 0
                        }
                    ]
                },
                "temperature": null,
                "movement": null
            },
            {
                "summaryDate": "${dates[0]}",
                "dayLabelAbbreviated": "${daysOfWeek[0]}",
                "usedSock": false,
                "pulseRate": {
                    "insight": "No Pulse Rate range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "60-79",
                            "value": 0
                        },
                        {
                            "range": "80-99",
                            "value": 0
                        },
                        {
                            "range": "100-119",
                            "value": 0
                        },
                        {
                            "range": "120-139",
                            "value": 0
                        },
                        {
                            "range": "140-159",
                            "value": 0
                        },
                        {
                            "range": "160-179",
                            "value": 0
                        },
                        {
                            "range": "180-199",
                            "value": 0
                        },
                        {
                            "range": "200-220",
                            "value": 0
                        }
                    ]
                },
                "oxygen": {
                    "insight": "No Oxygen Saturation range data.",
                    "min": null,
                    "max": null,
                    "avg": null,
                    "trending": null,
                    "distribution": [
                        {
                            "range": "85-89",
                            "value": 0
                        },
                        {
                            "range": "90-94",
                            "value": 0
                        },
                        {
                            "range": "95-100",
                            "value": 0
                        }
                    ]
                },
                "temperature": null,
                "movement": null
            }
        ]
    },
    "morningReport": {
        "name": "accounts/${accountId}/profiles/${serviceUserId}/morning-report",
        "reportDateStart": "${dates[0]}",
        "reportDateEnd": "${tomorrowDate}",
        "defaultWindow": false,
        "defaultWindowStart": "19:00",
        "nightWindowStart": "19:00",
        "nightWindowEnd": "07:00",
        "sleepGrade": "DREAMY",
        "totalSleepMinutes": 499,
        "wakings": 4,
        "sleepStateDurationsMinutes": {
            "0": 9,
            "1": 4,
            "8": 382,
            "15": 242
          },
        "longestSleepSegmentMinutes": 224,
        "funInsight": "Babies around this age begin to show more predictable sleep patterns, making it easier to start establishing bedtime routines.",
        "sleepInsight": "Last night ${childName} spent 6 hours and 39 minutes in bed, and slept for 8 hours and 19 minutes.",
        "sleepInsightTitle": "${childName} at 3 Months, 11 Days",
        "sleepGraph": {
                "sleepStates": [{"sleepState":1,"startTime":"${dates[1]}T23:30:00${timezone}"}, {"sleepState":8,"startTime":"${dates[1]}T23:54:00${timezone}"}, {"sleepState":15,"startTime":"${dates[0]}T08:32:00${timezone}"}],
                "sleepEndTime": "${dates[0]}T09:01:00${timezone}"},
        "combinedSleepPositionDurationMinutes":{
          "BACK": 432,
          "SIDE": 38,
          "TUMMY": 10
        },
        "combinedSleepPositionGraph":{
          "BACK": {
              "durationMinutes": 432,
              "percentageOfNightWindow": 90,
              "pulseRateAvg": 160,
              "oxygenAvg": 99
          },
          "SIDE":  {
              "durationMinutes": 38,
              "percentageOfNightWindow": 8,
              "pulseRateAvg": 130,
              "oxygenAvg": 96
          },
          "TUMMY":  {
              "durationMinutes": 10,
              "percentageOfNightWindow": 2,
              "pulseRateAvg": 145,
              "oxygenAvg": 93
          }
        },
        "sleepPositionInsight": "${childName} spends time sleeping on their back, side, and tummy. For safety, babies this age should sleep exclusively on their back."

    },
    "cameraSummary": {
        "name": "accounts/${accountId}/profiles/${serviceUserId}/camera-summary",
        "temperatureUnit": "F",
        "environmentInsight": "Today's average room humidity is trending lower than the past week.",
        "roomTemperatureInsights": {
            "trendInsight": "Today's average is 66.9°F, right on trend compared to the week's average of 68.6°F.",
            "weeklyAvg": 68.6,
            "hourlyHistory": [
                {
                    "dateTime": "${dates[0]}T${cam12hours[11]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[10]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[9]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[8]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[7]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[6]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[5]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[4]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[3]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[2]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[1]}${timezone}",
                    "value": 66.2
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[0]}${timezone}",
                    "value": 66.2
                }
            ],
            "historyAvg": 66.2,
            "historyInsight": "During the last 12 hours, the average temperature was 66.2°F, below the recommended range of 68°F to 72°F."
        },
        "roomHumidityInsights": {
            "trendInsight": "Today's average is 48.1%, which is noticeably below the week's average of 59.7%.",
            "weeklyAvg": 59.7,
            "hourlyHistory": [
                {
                    "dateTime": "${dates[0]}T${cam12hours[11]}${timezone}",
                    "value": 49
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[10]}${timezone}",
                    "value": 49
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[9]}${timezone}",
                    "value": 49
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[8]}${timezone}",
                    "value": 49.3
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[7]}${timezone}",
                    "value": 48.7
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[6]}${timezone}",
                    "value": 48
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[5]}${timezone}",
                    "value": 48
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[4]}${timezone}",
                    "value": 47.5
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[3]}${timezone}",
                    "value": 47
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[2]}${timezone}",
                    "value": 47.3
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[1]}${timezone}",
                    "value": 47
                },
                {
                    "dateTime": "${dates[0]}T${cam12hours[0]}${timezone}",
                    "value": 47
                }
            ],
            "historyAvg": 48.1,
            "historyInsight": "During the last 12 hours, the average humidity was 48.1%, which is within the ideal range of 40%-60%."
        },
        "sevenDay": [
            {
                "summaryDate": "${dates[0]}",
                "dayLabelAbbreviated": "${daysOfWeek[0]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 69.8,
                    "max": 69.8,
                    "avg": 69.8,
                    "condition": "IN_RANGE"
                },
                "roomHumidity": {
                    "min": 68,
                    "max": 71,
                    "avg": 68.6,
                    "condition": "HUMID"
                }
            },
            {
                "summaryDate": "${dates[1]}",
                "dayLabelAbbreviated": "${daysOfWeek[1]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 68,
                    "max": 69.8,
                    "avg": 69,
                    "condition": "IN_RANGE"
                },
                "roomHumidity": {
                    "min": 54,
                    "max": 69,
                    "avg": 61.6,
                    "condition": "HUMID"
                }
            },
            {
                "summaryDate": "${dates[2]}",
                "dayLabelAbbreviated": "${daysOfWeek[2]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 66.2,
                    "max": 68,
                    "avg": 67.4,
                    "condition": "COOL"
                },
                "roomHumidity": {
                    "min": 55,
                    "max": 61,
                    "avg": 57.3,
                    "condition": "IN_RANGE"
                }
            },
            {
                "summaryDate": "${dates[3]}",
                "dayLabelAbbreviated": "${daysOfWeek[3]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 68,
                    "max": 68,
                    "avg": 68,
                    "condition": "IN_RANGE"
                },
                "roomHumidity": {
                    "min": 55,
                    "max": 61,
                    "avg": 58.3,
                    "condition": "IN_RANGE"
                }
            },
            {
                "summaryDate": "${dates[4]}",
                "dayLabelAbbreviated": "${daysOfWeek[4]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 68,
                    "max": 69.8,
                    "avg": 69.6,
                    "condition": "IN_RANGE"
                },
                "roomHumidity": {
                    "min": 59,
                    "max": 66,
                    "avg": 62.6,
                    "condition": "HUMID"
                }
            },
            {
                "summaryDate": "${dates[5]}",
                "dayLabelAbbreviated": "${daysOfWeek[5]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 66.2,
                    "max": 69.8,
                    "avg": 68.5,
                    "condition": "IN_RANGE"
                },
                "roomHumidity": {
                    "min": 46,
                    "max": 64,
                    "avg": 53.9,
                    "condition": "IN_RANGE"
                }
            },
            {
                "summaryDate": "${dates[6]}",
                "dayLabelAbbreviated": "${daysOfWeek[6]}",
                "oxygen": null,
                "temperature": null,
                "movement": null,
                "roomTemperature": {
                    "min": 66.2,
                    "max": 68,
                    "avg": 66.9,
                    "condition": "COOL"
                },
                "roomHumidity": {
                    "min": 46,
                    "max": 50,
                    "avg": 48.1,
                    "condition": "IN_RANGE"
                }
            }
        ]
    }
}
  `

  const [dataTemplate, setDataTemplate] = useState(defaultTemplate)

  const onGenerateClick = async () => {
    const injectInsightsDataResult = await injectInsightsData(accountId, serviceUserId, JSON.parse(dataTemplate))

    if (injectInsightsDataResult) {
      snackbar.openSnackbar(
        `Inject insights data request sent successfully. Please wait a few minutes for sleep data to appear in app.`
      )
    } else {
      snackbar.openSnackbar(
        'Error injecting insights data. Please try again.'
      )
    }

    setIsButtonDisabled(true)
  }
  const onDataTemplateChange = (value) => {
    setDataTemplate(value)
    setIsButtonDisabled(false)

    try{
      JSON.parse(dataTemplate)
    }
    catch (e) {
      snackbar.openSnackbar(
        'Error parsing template, please verify it is a valid JSON.'
      )
    }

  }

  return (
    <div>
      <textarea
        rows={50}
        cols={70}
        onChange={ (e) => onDataTemplateChange(e.target.value)}
        value={dataTemplate}
        style={{padding: 10}}
      />
      <Button
        className="emptyBtn emptySmaller"
        disabled={isButtonDisabled}
        variant="outlined"
        onClick={onGenerateClick}>
        Inject insights response data. Auto expires after an hour.
      </Button>
    </div>
  )
}

InjectInsightsData.propTypes = {
  authUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.string
    ),
    roles: PropTypes.shape(PropTypes.string),
  }),
  currentDevice: PropTypes.shape({
    accountId: PropTypes.string,
    deviceId: PropTypes.string,
    dsn: PropTypes.string,
    serviceUserId: PropTypes.string,
    type: PropTypes.string,
    userProfile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
    }),
  }),
}

InjectInsightsData.defaultProps = {
  authUser: {},
  currentDevice: {},
}

export default InjectInsightsData
